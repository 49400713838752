import React from "react";
import {AdjustmentsVerticalIcon, CalendarDaysIcon, HomeIcon, RectangleStackIcon} from "@heroicons/react/24/outline";
import {ProjectType} from "@feature/commons/types";

const Home = React.lazy(() =>
  import("@pages/Home")
);
const PlanningRuns = React.lazy(() =>
  import("@pages/PlanningRuns")
);
const SchedulingRuns = React.lazy(() =>
  import("@pages/SchedulingRuns")
);
const NewPlanningRunSummary = React.lazy(() =>
  import("@pages/NewPlanningRunSummary")
);
const NewSchedulingRunSummary = React.lazy(() =>
  import("@pages/NewSchedulingRunSummary")
);
const DataEntry = React.lazy(() =>
  import("@pages/DataEntry")
);

/* 
  "planning" and "scheduling" sections are conditionally rendered based on the environment variable REACT_APP_ELT_PROJECT
  passed during npm build or start commands.
  If the value is "plan" or "both", the "planning" section is rendered.
  If the value is "sched" or "both", the "scheduling" section is rendered.
*/
const routes = [
  {
    name: "Home",
    href: "/home",
    Icon: HomeIcon,
    component: Home,
    hidden: false,
  },
  ...[ProjectType.plan, ProjectType.both].includes(ProjectType[process.env.REACT_APP_ELT_PROJECT])
    ? [
      {
        name: "planning",
        Icon: CalendarDaysIcon,
        dropdown: [
          {
            scopes: ["task_control:read"],
            name: "planning_list",
            href: "/runs_planning",
            component: PlanningRuns,
          },
          {
            scopes: ["task_control:read"],
            name: "manage_planning",
            href: "/manage_planning",
            component: NewPlanningRunSummary,
          },
        ],
      }
    ]
    : [],
  ...[ProjectType.sched, ProjectType.both].includes(ProjectType[process.env.REACT_APP_ELT_PROJECT])
    ? [
      {
        name: "scheduling",
        Icon: RectangleStackIcon,
        dropdown: [
          {
            scopes: ["task_control:read"],
            name: "scheduling_list",
            href: "/runs_scheduling",
            component: SchedulingRuns,
          },
          {
            scopes: ["task_control:read"],
            name: "manage_scheduling",
            href: "/manage_scheduling",
            component: NewSchedulingRunSummary,
          },
        ],
      }
    ]
    : [],
  {
    name: "data_entry",
    href: "/data_entry",
    Icon: AdjustmentsVerticalIcon,
    component: DataEntry,
    hidden: false,
    scopes: ["task_control:create"],
  },
];

export default routes;
