import React from "react";
import {IntlShape} from "react-intl";
import {MTColumnsType} from "@utils/pagination";
import {GanttBlocksType, IntervalType} from "@feature/commons/gantt/types";
import {CustomFilterDatePicker} from "@components/Tables/Params/CustomDatePicker";
import {TIMESTAMP_OPTIONS} from "@feature/commons/lib";

type T = GanttBlocksType;

export const getColumns = (intl: IntlShape): MTColumnsType<T> => [
  {
    title: intl.formatMessage({id: "cod_machine"}),
    field: "cod_machine",
    type: "string"
  },
  {
    title: intl.formatMessage({id: "cod_customer"}),
    field: "cod_customer",
    type: "string"
  },
  {
    title: intl.formatMessage({id: "cod_production"}),
    field: "cod_production",
    type: "numeric"
  },
  {
    title: intl.formatMessage({id: "cod_type_interval"}),
    field: "cod_type_interval",
    lookup: Object.entries(
      IntervalType
    ).reduce(
      (acc, [key, value]) => ({...acc, [key]: intl.formatMessage({id: value})}), {}
    )
  },
  {
    title: intl.formatMessage({id: "start"}),
    field: "tms_start",
    type: "datetime",
    cellStyle: {minWidth: 200},
    filterCellStyle: {minWidth: 200},
    filterComponent: (props) => <CustomFilterDatePicker {...props} showTimePicker/>,
    render: (rowData: T) => new Intl.DateTimeFormat('it-IT', TIMESTAMP_OPTIONS).format(new Date(rowData.tms_start))
  },
  {
    title: intl.formatMessage({id: "end"}),
    field: "tms_end",
    type: "datetime",
    cellStyle: {minWidth: 200},
    filterCellStyle: {minWidth: 200},
    filterComponent: (props) => <CustomFilterDatePicker {...props} showTimePicker/>,
    render: (rowData: T) => new Intl.DateTimeFormat('it-IT', TIMESTAMP_OPTIONS).format(new Date(rowData.tms_end))
  },
]