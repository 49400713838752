import {useIntl} from "react-intl";
import {RunFiltersProps} from "@feature/sched/types";
import {MultiDatePicker} from "@ui-components/MultiDatePicker";


export function RunFilters(props: RunFiltersProps) {


  const intl = useIntl();

  const msg = (id: string) => intl.formatMessage({id});

  return (
    <div className="flex flex-col xl:flex-row justify-evenly gap-5 items-center">
      <MultiDatePicker
        datePeriod={props.dateRange}
        setDatePeriod={props.setDateRange}
        labels={[msg('from'), msg('to')]}
        classNames="w-1/2"/>
    </div>
  )
}