import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import {useFetchKPIs, useFetchMachines} from "./hooks";
import {GanttKpiPayloadType} from "./types";
import {GanttBlocksType} from "@feature/commons/gantt/types";
import {GanttAndKpiFilters} from "./gantt-and-kpi-filters";
import {GanttChart, GanttTable} from "@feature/commons/gantt";
import {GaugeChart} from "@feature/commons/gauge";
import Alert from "@ui-components/Alert";
import {addMonths, dateToString, subtractMonths} from "@utils/utils";
import {BlurredPlaceholder} from "@feature/commons";
import {useFetchGanttData} from "@feature/commons/hooks";
import {EltType} from "@feature/commons/types";

type T = GanttBlocksType;

export function GanttAndKpi({idRun, group, enableEdit}: { idRun: number, group: string, enableEdit: boolean }) {

  const [refetchBlocks, setRefetchBlocks] = useState<boolean>(false);

  const [ganttAndKpiFilters, setGanttAndKpiFilters] = useState<GanttKpiPayloadType>(
    {
      machines: [],
      date_start: dateToString(subtractMonths(new Date(), 3)),
      date_end: dateToString(new Date()),
      toggleTable: false,
    }
  )

  const {loading: machinesLoading, machines: options} = useFetchMachines(idRun, group, EltType.sched);

  const {loading: KpiLoading, kpi} = useFetchKPIs(
    idRun,
    EltType.sched,
    {
      machines: ganttAndKpiFilters.machines,
      dat_start: ganttAndKpiFilters.date_start,
      dat_end: ganttAndKpiFilters.date_end
    }
  )


  const {loading: blocksLoading, blocks: blocksData, setBlocks: setBlocksData} = useFetchGanttData(
    idRun,
    EltType.sched,
    {...ganttAndKpiFilters, group},
    refetchBlocks
  )

  // get the min and max date from blocksData to set the date range for the date pickers
  const {datStart, datEnd} = useMemo(
    () => ({
        datStart: new Date(
          Math.min(
            ...blocksData?.map(
              el => new Date(el.tms_start).getTime()
            ) ?? [subtractMonths(new Date(), 3).getTime()]
          ),
        ),
        datEnd: new Date(
          Math.max(
            ...blocksData?.map(
              el => new Date(el.tms_end).getTime()
            ) ?? [addMonths(new Date(), 12).getTime()]
          ),
        )
      }
      // FIXME: if adding blocksData to the dependencies, it will cause an infinite loop
    ), [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  // start with all machines selected as default
  // start with all machines selected as default
  useEffect(() => {
      if (machinesLoading)
        return
      setGanttAndKpiFilters(
        p => (
          {
            ...p,
            machines: options?.map(el => el.value) ?? [],
            date_start: dateToString(datStart),
            date_end: dateToString(datEnd)
          }
        )
      )
    },
    [options, machinesLoading, datStart, datEnd]
  )

  return (
    <BlurredPlaceholder loading={machinesLoading || !options || KpiLoading || !kpi || blocksLoading || !blocksData}>
      <GanttAndKpiFilters
        ganttAndKpiFilters={ganttAndKpiFilters}
        setGanttAndKpiFilters={setGanttAndKpiFilters}
        datStart={datStart}
        datEnd={datEnd}
        options={options ?? []}/>
      {
        ganttAndKpiFilters.machines && ganttAndKpiFilters.machines.length > 0
          ? (
            ganttAndKpiFilters.toggleTable
              ? <GanttTable idRun={idRun} categoriesData={blocksData ?? []} eltType={EltType.sched}/> :
              <>
                <GanttChart
                  eltType={EltType.sched}
                  categoriesData={blocksData ?? []}
                  setCategoriesData={setBlocksData as Dispatch<SetStateAction<T[]>>}
                  id="hisMajestyTheGantt"
                  editable
                  machineOptions={options!}
                  setRefetchBlocks={setRefetchBlocks}
                  enableEdit={enableEdit}
                  filterParams={{
                    idRun,
                    group,
                    ...ganttAndKpiFilters
                  }}/>
                {/* mb-4 to avoid flickering when tooltip is opened */}
                <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 xl:mb-4">
                  {
                    kpi && kpi.map(el => (
                      <GaugeChart
                        key={el.id_kpi}
                        id={el.id_kpi.toString()}
                        data={el.val_average_mean * 100}
                        seriesLabel={el.name_kpi}
                        tooltipText={el.des_kpi}
                      />
                    ))
                  }
                </div>
              </>
          )
          : <Alert title="choose a machine" classNames="mx-auto my-20"/>
      }
    </BlurredPlaceholder>
  )
}
