import {useContext, useState} from "react";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {Query} from "@material-table/core";
import {fetchPagination} from "@feature/commons/lib";
import {MTColumnsType} from "@utils/pagination";


export const usePaginatedDataEntry = <T extends object>(
    table_name: string,
    columns: MTColumnsType<T>,
) => {

    // context
    const {push} = useContext(NotificationsContext);
    const throwError = useAsyncError();


    // state
    const [loading, setLoading] = useState<boolean>(false);

    const baseUrl = `/data_entry/${table_name}/paginated`;

    const fetch = async (query: Query<T>) => fetchPagination(
        baseUrl,
        query,
        columns,
        undefined,
        undefined,
        push,
        setLoading,
        throwError,
    )


    return {fetch, loading};
}