import {ArrowPathIcon, CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {StatusBadgePropsType} from "../types";
import {useIntl} from "react-intl";

export function StatusBadge({status, ...props}: StatusBadgePropsType) {
	
	const intl = useIntl();
	
	const defaultStyle: HTMLElement['className'] = 'h-5 w-5';
	
	let icon;
	let bgColor;
	let msg;
	if (status.startsWith('PRS') && status.endsWith("SUCCESS")) {
		icon = <CheckCircleIcon className={defaultStyle}/>;
		bgColor = 'rgb(77,150,85)';
		msg = status;
	} else if (status.endsWith("ERROR")) {
		icon = <XCircleIcon className={defaultStyle}/>;
		bgColor = 'rgb(155,44,44)';
		msg = status;
	} else {
		icon = <ArrowPathIcon className={`${defaultStyle} animate-spin`}/>
		bgColor = 'rgb(0, 108, 141)';
		msg = intl.formatMessage({id: 'executing'})
	}
	return (
		<div
			{...props}
			style={{backgroundColor: bgColor}}
			className={`flex rounded-3xl uppercase text-white p-3 font-semibold text-xs items-center justify-between ${props.className ?? ''}`}>
			<span className="w-1/2 text-center">{msg}</span>
			{icon}
		</div>
	)
}
