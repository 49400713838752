const anagram_params = {
  CLIENT: "customer priority",
  DEADLINE: "deadline priority",
  ENERGY: "energy saving",
  WASTE: "waste reduction",
  BALANCED: "balanced",
}


const data_entry_tabs = {
  alto_vendenti: "High Sellers",
  articoli_approvati: "Approved Articles",
  articolo_pose_risorse: "Resource Choice",
  clienti: "Customers",
  lista_macchine: "Machine List",
  manutenzioni: "Scheduled Stops",
  ordini: "Orders",
  ordini_simulati: "Simulated Orders",
  parametri: "Parameters",
  produzioni: "Productions",
}

export const anagram_en = {
  launch_plan: "Launch New Planning Run",
  launch_sched: "Launch New Scheduling Run",
  plan_title: "Planning Title",
  sched_title: "Scheduling Title",
  add_plan: "Add Planning",
  add_sched: "Add Scheduling",
  anagram_params: "Anagram Parameters",
  users_displayed_record: "{from}-{to} of {count} record",
  empty_table: "No records to display",
  title: "Title",
  success: "Operation Completed Successfully",
  generic_error: "An Error Occurred, please contact support",
  working_center: "Working Center",
  apply: "Apply",
  show_inserted_params: "Show inserted parameters",
  select_working_center: "Please select a working center",
  role_read_not_enabled: "Roles read not enabled",
  entity_task_control: "Management of control tasks in the system",
  executing: "Executing",
  new_start_time: "New Start Time",
  new_end_time: "New End Time",
  missing_order_infos: "Orders for which further information is required",
  last_run: "Last run",
  approved: "Approved",
  to_be_approved: "To be approved",
  active: "Active",
  inactive: "Inactive",
  cod_article: "Article Code",
  production_start_date: "Production start date",
  production_end_date: "Production end date",
  reordered_pieces_amount: "Reordered pieces",
  cod_resource: "Resource code",
  status: "Status",
  resources: "Resources",
  customer: "Customer",
  priority: "Priority",
  type: "Type",
  year: "Year",
  machine_serial_number: "Serial number",
  department: "Department",
  max_length: "Maximum length",
  max_width: "Maximum width",
  balloon: "Balloon",
  stacker: "Stacker",
  min_speed: "Minimum speed",
  max_speed: "Maximum speed",
  cost: "Cost",
  maintenance_start_date: "Maintenance start date",
  maintenance_end_date: "Maintenance end date",
  category: "Category",
  cod_order: "Order code",
  num_expected_poses: "Number of expected poses",
  expected_cycles_per_hour: "Expected cycles per hour",
  order_insertion_date: "Order insertion date",
  raw_material_code: "Raw material code",
  necessary_raw_material: "Necessary raw material",
  raw_material_arrival_date: "Raw material arrival date",
  assigned_resource: "Assigned resource",
  ordered_pieces: "Ordered pieces",
  valid_pieces: "Valid pieces",
  third_party_contractor: "Third party contractor",
  delivery_date: "Delivery date",
  is_order_mandatory: "Order mandatory",
  deposit: "Deposit",
  compiled: "Compiled",
  first_tranche_quantity: "First tranche quantity",
  first_tranche_date: "First tranche date",
  second_tranche_quantity: "Second tranche quantity",
  second_tranche_date: "Second tranche date",
  cod_part: "Part code",
  yes: "Yes",
  value: "Value",
  constraint_name: "Constraint name",
  poses: "Poses",
  allowed_on_resource_3: "Allowed on resource 3",
  mold_bell_length: "Mold bell length",
  mold_bell_width: "Mold bell width",
  chain: "Chain",
  pincher: "Pincher",
  back_to_schedule: "Back to schedule",
  alerts: "Alerts",
  user: "User",
  required: "Required",
  parameters: "Parameters",
  workers: "Workers",
  val_quantita_tot: "Total quantity",
  other_tranche_quantity: "Other tranche quantity",
  work_days: "Work days",
  equipment: "Attrezzatura",
  quantity: "Quantity",
  input_placeholder: "Filter ...",
  data_registry: "Data Registry",
  logging: "Logging",
  reparti: "Departments",
  production: "Production",
  setup: "Setup",
  maintenance: "Planned Downtime",
  ...anagram_params,
  ...data_entry_tabs
}