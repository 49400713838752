import {Color as amColor} from "@amcharts/amcharts5";
import {Dispatch, SetStateAction} from "react";
import {EltType, SelectOptionsSchema} from "@feature/commons/types";

/**
 * DictBlockInformationSchema
 * this is the schema that validates the custom fields of each anagram project.
 * Update accordingly
 */
export type DictBlockInformationSchema = object;

/**
 * DictOrderInformationSchema
 * this is the schema that validates the custom fields of each anagram project.
 * Update accordingly
 */
export type DictOrderInformationSchema = object;

export enum IntervalType {
  production = "production",
  setup = "setup",
  maintenance = "maintenance",
}

/** OrderSchema */
export interface OrderSchema {
  /** Cod Order */
  cod_order: string;
  /** Cod Customer */
  cod_customer: string;
  /** Des Customer */
  des_customer: string;
  /** Cod Item */
  cod_item: string;
  /** Des Item */
  des_item: string;
  /**
   * this is the schema that validates the custom fields of each anagram project.
   * Update accordingly
   */
  dict_order_information: DictOrderInformationSchema;
}

export interface GanttBlockSchema {
  /** Cod Production */
  cod_production: string;
  /**
   * Tms Start
   * @format date-time
   */
  tms_start: string;
  /**
   * Tms End
   * @format date-time
   */
  tms_end: string;
  /** An enumeration. */
  cod_type_interval: IntervalType;
  /** Id Color */
  id_color: number;
  /** Cod Machine */
  cod_machine: string;
  /** Des Machine */
  des_machine: string;
  /** Des Block */
  des_block: string;
  /** Cod Production Identikit */
  cod_production_identikit: string;
  /**
   * this is the schema that validates the custom fields of each anagram project.
   * Update accordingly
   */
  dict_block_information: DictBlockInformationSchema;
  /** Cod Gantt Group */
  cod_gantt_group: string;
  /** Des Gantt Group */
  des_gantt_group: string;
  /** F Orders */
  f_orders: OrderSchema[];
}

export interface GanttBlocksType extends GanttBlockSchema {
  columnSettings: { fill: amColor, stroke: amColor }
}

export interface GanttBlockUpdateSchema {
  /** Cod Production */
  cod_production: string;
  /**
   * Tms Start
   * @format date-time
   */
  tms_start: string;
  /**
   * Tms End
   * @format date-time
   */
  tms_end: string;
  /** An enumeration. */
  cod_type_interval: IntervalType;
  /** Id Color */
  id_color: number;
  /** Cod Machine */
  cod_machine: string;
  /** Des Machine */
  des_machine: string;
  /** Des Block */
  des_block: string;
  /** Cod Production Identikit */
  cod_production_identikit: string;
  /**
   * this is the schema that validates the custom fields of each anagram project.
   * Update accordingly
   */
  dict_block_information: DictBlockInformationSchema;
}

/** GanttPayloadSchema */
export interface GanttPayload {
  /**
   * Date Start
   * @format date
   */
  date_start: string;
  /**
   * Date End
   * @format date
   */
  date_end: string;
  /**
   * Machines
   * @default []
   */
  machines: string[];
}

export interface GanttPayloadSchema extends GanttPayload {
  /** Group */
  group: string;
}

/** GanttUpdateSchema */
export interface GanttUpdateSchema {
  /** New Values */
  new_values: GanttBlockUpdateSchema[];
}


export interface FilterParamsType extends GanttPayload {
  idRun: number;
  group: string
}

export type FilterParamsTupleType = [string, Date, Date, string, SelectOptionsSchema[]]

export interface GanttChartProps {
  id: string;
  categoriesData: GanttBlocksType[];
  setCategoriesData: Dispatch<SetStateAction<GanttBlocksType[]>>;
  editable: boolean;
  machineOptions: SelectOptionsSchema[];
  setRefetchBlocks: Dispatch<SetStateAction<boolean>>;
  filterParams: FilterParamsType;
  eltType: EltType;
  enableEdit: boolean;
}

export interface LabelsType {
  [key: string]: { des_block: string, color: amColor }
}

export interface EditModalProps {
  block: GanttBlocksType
  setBlock: Dispatch<SetStateAction<GanttBlocksType | undefined>>
  categoriesData: GanttBlocksType[]
  setCategoriesData: Dispatch<SetStateAction<GanttBlocksType[]>>
  machineOptions: SelectOptionsSchema[]
  eltType: EltType
  editGantt: boolean
}

export interface GanttTableProps {
  idRun: number;
  eltType: EltType;
  categoriesData: GanttBlocksType[];
  setShowGantt?: Dispatch<SetStateAction<GanttBlocksType | undefined>>;
}