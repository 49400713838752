import React from "react";
import {useIntl} from "react-intl";
import {ChartBarSquareIcon, TableCellsIcon} from "@heroicons/react/24/outline";
import {GanttAndKpiFiltersProps} from "./types";
import DatePicker from "@ui-components/DatePicker";
import {dateToString} from "@utils/utils";
import SearchSelect from "@ui-components/SearchSelect";
import {SelectOptionsSchema} from "@feature/commons/types";
import Toggle from "@ui-components/Toggle";


export function GanttAndKpiFilters({
                                     ganttAndKpiFilters,
                                     setGanttAndKpiFilters,
                                     options,
                                     datStart,
                                     datEnd
                                   }: GanttAndKpiFiltersProps) {

  const labelStyle = "font-semibold text-am-600"

  const intl = useIntl();

  return (
    <div className="grid grid-cols-3 gap-x-5 gap-y-10 place-items-center my-10 px-5">
      <div className="flex justify-between items-center gap-x-2">
        <DatePicker
          value={new Date(ganttAndKpiFilters.date_start)} classNames="w-32"
          label={intl.formatMessage({id: "from"})}
          labelClassNames={labelStyle}
          onChange={(d: Date) => setGanttAndKpiFilters(p => ({...p, date_start: dateToString(d)}))}
          minDate={datStart}
          maxDate={new Date(ganttAndKpiFilters.date_end)}/>
        <DatePicker
          value={new Date(ganttAndKpiFilters.date_end)} classNames="w-32"
          label={intl.formatMessage({id: "to"})}
          labelClassNames={labelStyle}
          onChange={(d: Date) => setGanttAndKpiFilters(p => ({...p, date_end: dateToString(d)}))}
          minDate={datStart}
          maxDate={datEnd}/>
      </div>
      <SearchSelect
        options={options}
        label={intl.formatMessage({id: "machines"})}
        labelStyle={labelStyle}
        classNames="w-full"
        onChange={(m: SelectOptionsSchema[]) => setGanttAndKpiFilters(p => (
          {...p, machines: m.map(e => e.value)}
        ))}
        value={options.filter(x => ganttAndKpiFilters.machines.includes(x.value))} isMulti/>
      <div className='flex justify-center items-center gap-x-5'>
        <ChartBarSquareIcon
          className={`w-7 h-7 ${ganttAndKpiFilters.toggleTable ? 'text-gray-400' : 'text-am-600'}`}/>
        <Toggle
          checked={ganttAndKpiFilters.toggleTable}
          onChange={() => setGanttAndKpiFilters(p => ({
            ...p,
            toggleTable: !p.toggleTable
          }))}
          vertical={false}
          label={undefined}
          description={undefined}/>
        <TableCellsIcon
          className={`w-7 h-7 ${ganttAndKpiFilters.toggleTable ? 'text-am-600' : 'text-gray-400'}`}/>
      </div>
    </div>
  )
}
