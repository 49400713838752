import en_faq from "./faq/en_faq";
import en_form from "./form/en";
import {anagram_en} from "./anagram/en";

const en = {
  entity_user: "Management of users in the system",
  entity_role: "Management of authorization roles in the system",
  name_and_surname: "Name and surname",
  entity_audit: "Management of audit in the system",
  language_it: "Italiano",
  language_en: "English",
  language: "Language",
  Home: "Home",
  disabled_user: "Disabled user",
  enter_name_and_surname: "Enter your name and surname",
  enter_name_or_email: "Enter email and name",
  need_to_select_a_role: "You must select a role",
  delete_user: "Delete the user?",
  disabled: "Disabled",
  user_updated_successfully: "User updated successfully",
  user_deleted_successfully: "User successfully deleted",
  user_created_successfully: "User successfully created",
  description: "Description",
  role_name: "Role name",
  add_role_name: "Enter the name of the role",
  add_role: "Add role",
  add_user: "Add user",
  deleted_role: "Role deleted",
  updated: "Updated",
  delete_element: "Are you sure you want to delete the element?",
  role_already_used: "Role already in use!",
  role_created_successfully: "Role created successfully",
  add: "Add",
  server_error: "Server Error",
  confirm_new_password: "Confirm password",
  passwords_not_matching: "The two passwords do not match",
  password_without_spaces: "The password must not contain spaces",
  password_length: "The password must be at least 8 characters long",
  signup: "Register",
  error_while_saving: "Error while saving",
  authentication_failed: "Authentication failed",
  send: "Send",
  user_not_found: "User not found",
  wrong_old_password: "Wrong old password",
  enter_actual_password: "Enter current password",
  actual_password: "Current password",
  new_password: "New password",
  email: "Email",
  name: "Name",
  role: "Role",
  user_type: "User type",
  my_profile: "My profile",
  access_denied: "Access denied",
  password_updated_successfully: "Password changed successfully",
  submit_password: "Send password",
  enter_your_email_address: "Enter the email address associated with your user",
  restore_password: "Recover password",
  forgot_password: "Forgot your password?",
  edit_password: "Change password",
  show_password: "Show password",
  form_error: "Check the form for errors",
  enter_email: "Enter email",
  missing_sender_mail: "Missing sender mail",
  log_into_your_account: "Log into your account",
  wrong_email_or_password: "Wrong email or password",
  unregistered_email: "Email not registered",
  forgot_password_msg: "Mail sent! If you don't hear from us in the next 15 minutes, please double check that you entered the correct email address and check your spam folder.",
  roles: "Roles",
  roles_displayed_rows: "{from}-{to} of {count} roles",
  users: "Users",
  users_displayed_rows: "{from}-{to} of {count} users",
  components: "Components",
  planning: "Planning",
  planning_list: "Planning List",
  manage_planning: "Manage Planning",
  scheduling: "Scheduling",
  scheduling_list: "Scheduling List",
  manage_scheduling: "Manage Scheduling",
  data_entry: "Data Entry",
  scenarios: "Scenarios",
  new_planning: "New Planning",
  new_scheduling: "New Scheduling",
  users_settings: "User management",
  order_type: "Order Type",
  plan_details: "Plan Details",
  schedule_details: "Schedule Details",
  orders_not_planned: "Orders not Planned",
  orders_not_scheduled: "Orders not Scheduled",
  edit_plan: "Edit Plan",
  heatmap: "heatmap",
  confirm_changes: "Confirm Changes",
  discard_changes: "Discard Changes",
  back: "Back",
  edit: "edit",
  exit_edit: "exit edit",
  from: "From",
  to: "To",
  machines: "Machines",
  new_machine: "New Machine",
  new_start_date: "New Start Date",
  new_end_date: "New End Date",
  welcome: "Welcome",
  test: "test_it",
  audit: "Audit",
  frontend: "Frontend",
  backend: "Backend",
  plan_runs_error: "Error while fetching Plan PlanningRuns",
  file_picker_placeholder: "Select one or more files",
  save_configuration: "Save Current Configuration",
  success_ticket_sent: "The ticket was added successfully, we will take the request in charge as soon as possibile.",
  hubspot_portal: "Hubspot Customer Portal",
  size_limit_exceeded: "Size limit exceeded",
  customer_portal_loading_failed: "Customer portal link loading error",
  week_datepicker: "Week ",
  week_datepicker_not_selected: "Weekly Datepicker",
  daily_datepicker_not_selected: "Daily Datepicker",
  thanks: "Thanks!",
  select_an_option: "Please select an option from the dropdown menu.",
  ticket_parameters_not_found: "Ticket parameters not found",
  troisi_parameters_not_found: "Mail parameters not found",
  error_new_ticket_creation: "An error occurred while creating a new ticket",
  error_ticket_hubspot_management: "New ticket is created, but went wrong during the operations made by our portal",
  error_ticket_file_attachment: "New ticket is created, but it's not possibile to attach the file specified",
  session_expired: "Session expired",
  refreshing_session_wait: "Refreshing session, please wait",
  launch_new_scheduling: "Launch new scheduling run",
  cod_machine: "Machine code",
  cod_customer: "Project",
  cod_type_interval: "State",
  cod_production: "Production code",
  dat_deadline: "Dead time",
  start: "Start",
  end: "End",
  label_display_rows_table: "{from}-{to} of {count} rows",
  label_rows_select_table: "Rows",
  production: "PRODUCTION",
  initial_setup: "SETUP",
  changing_mold: "CHANGE MOLD",
  ...en_faq,
  ...en_form,
  ...anagram_en,
};

export default en;
