const anagram_params = {
  CLIENT: "priorità cliente",
  DEADLINE: "priorità scadenze",
  ENERGY: "risparmio energetico",
  WASTE: "riduzione scarti",
  BALANCED: "bilanciato",
}

const data_entry_tabs = {
  alto_vendenti: "Altovendenti",
  articoli_approvati: "Articoli Approvati",
  articolo_pose_risorse: "Scelta Risorse",
  clienti: "Clienti",
  lista_macchine: "Lista Macchine",
  manutenzioni: "Fermi Programmati",
  ordini: "Ordini",
  ordini_simulati: "Ordini Simulati",
  parametri: "Parametri",
  produzioni: "Produzioni",
}

export const anagram_it = {
  launch_plan: "Lancia nuova pianificazione",
  launch_sched: "Lancia nuova schedulazione",
  plan_title: "titolo pianificazione",
  sched_title: "titolo schedulazione",
  add_plan: "aggiungi pianificazione",
  add_sched: "aggiungi schedulazione",
  anagram_params: "parametri anagram",
  users_displayed_record: "{from}-{to} di {count} record",
  empty_table: "Nessun risultato disponibile",
  title: "Titolo",
  success: "Operazione Completata con Successo",
  generic_error: "Si è verificato un errore; contatta l'assistenza",
  working_center: "Centro di Lavorazione",
  apply: "Applica",
  show_inserted_params: "Visualizza parametri inseriti",
  select_working_center: "Seleziona un centro di lavorazione",
  role_read_not_enabled: "Lettura dei ruoli non abilitata",
  entity_task_control: "Gestione dei task di controllo in sistema",
  executing: "In esecuzione",
  new_start_time: "Nuovo Orario Inizio",
  new_end_time: "Nuovo Orario Fine",
  missing_order_infos: "Ordini per i quali sono richieste informazioni ulteriori",
  last_run: "Ultima esecuzione",
  approved: "Approvato",
  to_be_approved: "Da approvare",
  active: "Attivo",
  inactive: "Inattivo",
  cod_article: "Codice Articolo",
  production_start_date: "Data inizio produzione",
  production_end_date: "Data fine produzione",
  reordered_pieces_amount: "Pezzi riordinati",
  cod_resource: "Codice Risorsa",
  status: "Stato",
  resources: "Risorse",
  customer: "Cliente",
  priority: "Priorità",
  type: "Tipologia",
  year: "Anno",
  machine_serial_number: "matricola",
  department: "Reparto",
  max_length: "Lunghezza massima",
  max_width: "Larghezza massima",
  balloon: "Pallone",
  stacker: "Impilatore",
  min_speed: "Velocità minima",
  max_speed: "Velocità massima",
  cost: "Costo",
  maintenance_start_date: "Data inizio manutenzione",
  maintenance_end_date: "Data fine manutenzione",
  category: "Categoria",
  cod_order: "Codice Ordine",
  num_expected_poses: "Numero pose previste",
  expected_cycles_per_hour: "Battute ora previste",
  order_insertion_date: "Data inserimento ordine",
  raw_material_code: "Codice materia prima",
  necessary_raw_material: "Materia prima necessaria",
  raw_material_arrival_date: "Data arrivo materia prima",
  assigned_resource: "Risorsa assegnata",
  ordered_pieces: "Pezzi ordinati",
  valid_pieces: "Pezzi buoni prodotti",
  third_party_contractor: "Terzista",
  delivery_date: "Data consegna",
  is_order_mandatory: "Tassatività ordine",
  deposit: "Acconto",
  compiled: "Compilato",
  first_tranche_quantity: "Quantità prima tranche",
  first_tranche_date: "Data prima tranche",
  second_tranche_quantity: "Quantità seconda tranche",
  second_tranche_date: "Data seconda tranche",
  cod_part: "Codice Parte",
  yes: "Sì",
  value: "Valore",
  constraint_name: "Nome vincolo",
  poses: "Pose",
  allowed_on_resource_3: "Consentito su risorsa 3",
  mold_bell_length: "Lunghezza campana",
  mold_bell_width: "Larghezza campana",
  chain: "Catena",
  pincher: "Pinza",
  back_to_schedule: "Torna alla schedulazione",
  alerts: "Allarmi",
  user: "Utente",
  required: "Obbligatorio",
  parameters: "Parametri",
  workers: "Operatori",
  val_quantita_tot: "Quantità totale",
  other_tranche_quantity: "Quantità altre tranche",
  work_days: "Giorni lavorativi",
  last_tranche_date: "Data ultima tranche",
  last_tranche_date_tooltip: "La data dell'ultima tranche verrà automaticamente calcolata durante la prossima elaborazione di Anagram. In seguito sarà modificabile manualmente.",
  equipment: "Attrezzatura",
  quantity: "Quantità",
  input_placeholder: "Filtra ...",
  data_registry: "Registro Dati",
  logging: "Logging",
  reparti: "Reparti",
  production: "Produzione",
  setup: "Attrezzaggio",
  maintenance: "Fermo Programmato",
  ...anagram_params,
  ...data_entry_tabs
}