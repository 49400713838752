import React, {useState} from "react";
import moment from 'moment';
import DatePicker from "../../../ui-components/DatePicker";
import {TimePicker} from "@feature/commons";

const divClassName = 'w-32 flex flex-col items-center gap-y-1 rounded-3xl bg-table-100 mx-auto'

export const CustomEditDatePicker = ({value, onChange, showTimePicker = false}) => {
  return <div className={divClassName}>
    {
      showTimePicker && (
        <TimePicker
          label={""}
          value={{
            hour: moment(value).hour(),
            minute: moment(value).minute()
          }}
          onHourChange={
            (hour) => {
              const date = moment(value).hour(hour).minute(moment(value).minute());
              onChange(date.format("YYYY-MM-DDTHH:mm:ss"));
            }
          }
          onMinuteChange={
            (minute) => {
              const date = moment(value).hour(moment(value).hour()).minute(minute);
              onChange(date.format("YYYY-MM-DDTHH:mm:ss"));
            }
          }/>
      )
    }
    <DatePicker
      value={value ? new Date(value) : new Date()}
      minDate={null}
      onChange={(e) => onChange(moment(e).format(showTimePicker ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DD"))}
      showErrorDialog={false}
      materialTable={true}
    />
  </div>
}

export const CustomFilterDatePicker = (props) => {
  const [date, setDate] = useState(null);
  return <div className={divClassName}>
    {
      props.showTimePicker && (
        <TimePicker
          label={""}
          value={date ? {
            hour: moment(date).hour(),
            minute: moment(date).minute()
          } : {
            hour: 0,
            minute: 0
          }
          }
          onHourChange={
            (hour) => {
              const _date = moment(date).hour(hour).minute(moment(date).minute());
              setDate(_date.toDate());
              props.onFilterChanged(props.columnDef.tableData.id, _date.toDate());
            }
          }
          onMinuteChange={
            (minute) => {
              const _date = moment(date).hour(moment(date).hour()).minute(minute);
              setDate(_date.toDate());
              props.onFilterChanged(props.columnDef.tableData.id, _date.toDate());
            }
          }/>
      )
    }
    <DatePicker
      value={date}
      minDate={null}
      onChange={(event) => {
        setDate(event);
        props.onFilterChanged(props.columnDef.tableData.id, event);
      }}
      onClear={() => {
        setDate(null);
        props.onFilterChanged(props.columnDef.tableData.id, null);
      }}
      showErrorDialog={false}
      materialTable={true}
    />
  </div>
}