import React, {useLayoutEffect} from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {GaugeChartParamsType} from "@feature/commons/gauge/types";
import {addLicense} from "@components/Charts/commons";
import {createRange} from "@feature/commons/gauge/lib";
import Tooltip from "@ui-components/Tooltip";


export function GaugeChart({id, data, seriesLabel, tooltipText}: GaugeChartParamsType) {
	
	const maxPercentage = 100;
	
	useLayoutEffect(() => {
		
		// temporary solution to not lose the clock hand in the chart
		const limitedData = data > maxPercentage ? maxPercentage : data;
		
		addLicense();
		
		const root = am5.Root.new(id);
		
		root.setThemes([
			am5themes_Animated.new(root)
		]);
		
		const chart = root.container.children.push(
			am5radar.RadarChart.new(root, {
				panX: false,
				panY: false,
				startAngle: -180,
				endAngle: 0,
				innerRadius: -26
			})
		);
		
		const axisRenderer = am5radar.AxisRendererCircular.new(root, {
			strokeOpacity: 0.1,
			minGridDistance: 30
		});
		
		axisRenderer.ticks.template.setAll({
			visible: true,
			strokeOpacity: 0.5
		});
		
		axisRenderer.grid.template.setAll({
			visible: false
		});
		
		const axis = chart.xAxes.push(
			am5xy.ValueAxis.new(root, {
				maxDeviation: 0,
				min: 0,
				max: maxPercentage,
				strictMinMax: true,
				renderer: axisRenderer
			})
		);
		
		const label = chart.radarContainer.children.push(am5.Label.new(root, {
			centerX: am5.percent(50),
			textAlign: "center",
			centerY: am5.percent(0),
			fontWeight: "bold"
		}));
		
		label.set("text", data.toString() + ' %');
		
		createRange({axis: axis, start: 0, end: data, color: am5.color('rgb(0, 71, 92)')});
		createRange({axis: axis, start: data, end: maxPercentage, color: am5.color('#9CA79D')});
		
		// Add clock hand
		const handDataItem = axis.makeDataItem({value: 0});
		
		handDataItem.set("bullet", am5xy.AxisBullet.new(root, {
			sprite: am5radar.ClockHand.new(root, {
				topWidth: 2,
				bottomWidth: 10,
				pinRadius: 5
			})
		}));
		
		axis.createAxisRange(handDataItem);
		
		handDataItem.get("grid")!.set("visible", false);
		handDataItem.get("tick")!.set("visible", false);
		
		setInterval(() => {
			handDataItem.animate({
				key: "value",
				to: limitedData,
				duration: 800,
				easing: am5.ease.out(am5.ease.cubic)
			});
		}, 200);
		
		// on component dismount, remove also root
		return () => root.dispose()
		
	}, [data, id, seriesLabel])
	
	return (
		<div className="flex flex-col items-center justify-center gap-y-2">
			<div id={id} style={{minHeight: "10rem", minWidth: "20rem"}}/>
			<strong className="-mt-2">{seriesLabel}</strong>
			{tooltipText && <Tooltip text={tooltipText} size="small" placement="bottom"/>}
		</div>
	)
}
