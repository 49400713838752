import Placeholder from "@ui-components/Placeholder";
import {useFetchLastRun} from "@feature/commons/hooks";
import {EltType} from "@feature/commons/types";
import {useIntl} from "react-intl";

export function LastRun() {
  const {loading, lastRun} = useFetchLastRun(EltType.sched);
  const intl = useIntl();


  return !loading && lastRun
    ? (
      <span
        className="rounded-md bg-am-200 text-white capitalize font-bold p-3 mx-10 border border-white outline outline-am-200 outline-3">
        {intl.formatMessage({id: 'last_run'})}: {new Intl.DateTimeFormat().format(lastRun)}
      </span>
    )
    : <Placeholder height="h-20"/>
}