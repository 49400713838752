import React, {useCallback} from "react";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

// Components
import Calendar from "react-calendar";
import Label from "./Label";

// Language
import {localeMapper} from "../locale";

// Constants
import {CALENDAR_MODE} from "../utils/constants";

const today = moment().startOf("day").toDate();
const tomorrow = moment(today).add(1, "day").toDate();
const nextYear = moment(today).add(1, "year").toDate();

const swapDates = (pairOfDates) => {
  return ([pairOfDates[1], pairOfDates[0]]);
}

const getDaysOfSelectedWeek = (date, minDate, maxDate, selectableDates) => {
  let arr = [];
  let day = 0;
  while (day < 7) {
    // to remove ambiguity on week, have to specify year and month
    const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + day++);

    // if maxDate or selectableDates are not specified, condition is automatically true
    if (newDate >= minDate && (maxDate ? newDate <= maxDate : true) &&
      (selectableDates ? selectableDates.find((x) => getStringDate(x) === getStringDate(newDate)) : true))
      arr.push(newDate);
  }
  return arr;
};

const getStringDate = (date) => date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : ""

const dateAlreadyClicked = (dates, setDates, date, range) => {
  if (range && dates.length > 2)
    setDates([])
  if (range)
    return dates[0] <= date && date <= dates[dates.length - 1]
  else
    return dates.map((d) => getStringDate(d)).indexOf(getStringDate(date)) >= 0
}

const monthAlreadyClicked = (dates, date) =>
  dates ? date.getFullYear() === dates.getFullYear() && date.getMonth() === dates.getMonth() : false

const getNewDate = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);

  return d;
};

const CalendarInput = ({
                         label = "",
                         dates,
                         setDates,
                         minDate = tomorrow,
                          maxDate = nextYear,
                         selectableDates = null,
                         calendarStartDate = today,
                         mode = CALENDAR_MODE.DAILY,
                         single = false,
                         view = undefined,
                         range = false,
                         hideCalendar
                       }) => {
  const tileClassName = useCallback(
    ({date, view}) => {
      switch (mode) {
        case CALENDAR_MODE.WEEKLY:
          if (view === "year") return null
          if (dateAlreadyClicked(getDaysOfSelectedWeek(calendarStartDate, minDate, maxDate, selectableDates), setDates, new Date(date), range)
            && dates)
            return "day-selected";
          return "active-day-not-selectable";
        case CALENDAR_MODE.MONTHLY:
          if (monthAlreadyClicked(dates, date))
            return "day-selected";
          return null;
        default:
          if (dateAlreadyClicked(dates, setDates, new Date(date), range)) return "day-selected";
          return null;
      }
    },
    [dates] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div className="flex flex-col" tabIndex="0">
      {label && (
        <>
          <Label>{label}</Label>
          <div className="mb-4"/>
        </>
      )}
      <Calendar
        className={["shadow bg-gray-50 rounded-3xl px-4 py-6 border-0"]}
        tileClassName={tileClassName}
        selectRange={range}
        onClickMonth={(e) => {
          if (mode === CALENDAR_MODE.MONTHLY)
            setDates(e)
        }}
        onClickDay={(e) => {
          if (mode !== CALENDAR_MODE.DAILY) return;
          const clickDate = getNewDate(e);

          if (single) {
            setDates([clickDate]);
            return;
          }
          if (dates.map((d) => getStringDate(d)).indexOf(getStringDate(clickDate)) >= 0) {
            setDates([...dates.filter((d) => getStringDate(d) !== getStringDate(clickDate))]);
            return;
          }

          const datesSelected = [...dates, clickDate];
          if (range && datesSelected.length === 2) {
            hideCalendar();
            if (datesSelected[0] > datesSelected[1]) {
              setDates(swapDates(datesSelected));
              return;
            }
          }
          setDates(datesSelected);
        }}
        minDate={minDate}
        maxDate={maxDate}
        locale={localeMapper[localStorage.getItem("lang")] ?? "en-EN"}
        tileDisabled={(tile) => {
          if (selectableDates)
            return !selectableDates.find((d) => d.toDateString() === tile.date.toDateString()
            );
        }}
        onClickWeekNumber={(week, day) => setDates(new Date(day.setHours(day.getHours() + 10)))}
        view={view}
        defaultActiveStartDate={calendarStartDate}
        showWeekNumbers={mode === CALENDAR_MODE.WEEKLY}
        week
      />
    </div>
  );
};

export default CalendarInput;
