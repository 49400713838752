import {useState} from "react";
import {useIntl} from "react-intl";
import {MultiDatePickerPropsType} from "./MultiDatePicker.type";
import DatePicker from "../DatePicker";
import Button from "../Button";
import {addMonths, subtractMonths} from "@utils/utils";

export function MultiDatePicker({setDatePeriod, labels, classNames = ""}: MultiDatePickerPropsType) {
	const intl = useIntl();

	const [date1, setDate1] = useState<Date>(subtractMonths(new Date(), 1));
	const [date2, setDate2] = useState<Date>(new Date());
	
	
	return (
		<div className={"flex items-end justify-evenly gap-x-5 " + classNames}>
			<div className="flex flex-col gap-y-2">
				{labels.length && <h3>{labels[0]}</h3>}
				<DatePicker value={date1} onChange={setDate1} minDate={subtractMonths(new Date(), 12)}
				            maxDate={addMonths(new Date(), 1)}/>
			</div>
			<div className="flex flex-col gap-y-2">
				{labels.length && <h3>{labels[1]}</h3>}
				<DatePicker value={date2} onChange={setDate2} minDate={date1}
				            maxDate={addMonths(new Date(), 1)}/>
			</div>
			<Button onClick={() => setDatePeriod([date1, date2])}>{intl.formatMessage({ id: "apply" })}</Button>
		</div>
	)
}