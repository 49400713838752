import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router";
import {RunSchedParams, SchedulingDetailsProps} from "@feature/sched/types";
import {EltType, SelectOptionsSchema} from "@feature/commons/types";
import SearchSelect from "@ui-components/SearchSelect";
import Placeholder from "@ui-components/Placeholder";
import RunParamsPopup from "@feature/commons/run-params-popup/run-params-popup";
import {GanttAndKpi} from "@feature/sched/scheduling-details/gantt-and-kpi";
import {useFetchGroups} from "@feature/commons/hooks";
import {SearchPlaceholder} from "@feature/commons";
import {useIntl} from "react-intl";


export function SchedulationDetails({
                                      homeRunId,
                                      client_vs_deadline_param,
                                      energy_vs_waste_param,
                                      enableEdit = false
                                    }: SchedulingDetailsProps) {

  const intl = useIntl();

  /* Get definitiveIdRun from URL Location (default) or props (passed in home-page) */
  const {id_run} = useParams<{ id_run?: string }>();
  if (id_run === undefined && homeRunId === undefined) {
    throw new Error("id_run is undefined");
  }
  const definitiveIdRun = id_run ?? homeRunId!;

  /* Get definitiveState from URL Location (default) or props (passed in home-page) */
  const {state} = useLocation<RunSchedParams>();
  if (state === undefined && (client_vs_deadline_param === undefined || energy_vs_waste_param === undefined)) {
    throw new Error("state is undefined");
  }
  const definitiveState = state ?? {client_vs_deadline_param, energy_vs_waste_param};

  const {loading: groupLoading, groups: groupOptions} = useFetchGroups(parseInt(definitiveIdRun), EltType.sched);

  const [costCenter, setCostCenter] = useState<SelectOptionsSchema>();

  useEffect(() => {
    // In home-page, set costCenter to the first option by default.
    if (!costCenter && groupOptions && groupOptions.length && homeRunId) {
      setCostCenter(groupOptions[0]);
    }
  }, [costCenter, groupOptions, homeRunId])

  return (
    <>
      <div className="flex justify-between items-end">
        {
          !groupLoading && groupOptions
            ? <SearchSelect
              label={intl.formatMessage({id: "working_center"})}
              labelStyle="font-semibold text-am-600"
              options={groupOptions}
              value={costCenter}
              classNames="w-60 inline"
              onChange={(e: SelectOptionsSchema) => setCostCenter(e)}/>
            : <Placeholder height="h-42"/>
        }
        <RunParamsPopup state={definitiveState}/>
      </div>
      {
        costCenter
          ? <GanttAndKpi idRun={parseInt(definitiveIdRun)} group={costCenter.value} enableEdit={enableEdit}/>
          : <SearchPlaceholder/>
      }
    </>
  )
}
