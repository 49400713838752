import {GanttBlocksType} from "@feature/commons/gantt/types";
import React from "react";
import {TIMESTAMP_OPTIONS} from "@feature/commons/lib";

export function IntervalInfo({block}: { block: GanttBlocksType }) {
  return (
    <div className="flex flex-col items-center gap-y-5">
      <div>
        {
          block.f_orders.map(
            o => (
              <li key={o.cod_order} className="list-none">
                <ul><strong>Codice Ordine: </strong>{o.cod_order}</ul>
                <ul><strong>Codice Articolo: </strong>{o.cod_item}</ul>
              </li>
            )
          )
        }
      </div>
      <div className="flex gap-x-3 items-center justify-between">
        <span><strong>Inizio: </strong>{new Intl.DateTimeFormat('it-IT', TIMESTAMP_OPTIONS).format(new Date(block.tms_start))}</span>
        <span><strong>Fine: </strong>{new Intl.DateTimeFormat('it-IT', TIMESTAMP_OPTIONS).format(new Date(block.tms_end))}</span>
      </div>
    </div>
  )
}