import {DtnErrorsSchema} from "@feature/data-entry/types";

const ERROR_MAPPING: Record<string, Array<keyof DtnErrorsSchema>> = {
  ordini_simulati: ['missing_order_infos'],
}


// return only the errors that are relevant to the tab
export const getDtnError = (tab: string, errors: DtnErrorsSchema) => {
  if (tab === "alerts")
    return errors;

  const mapping = ERROR_MAPPING[tab];

  if (!mapping)
    return {} as DtnErrorsSchema;

  return Object.entries(errors).reduce((acc, [key, value]) => {
    if (mapping.includes(key as keyof DtnErrorsSchema))
      acc[key as keyof DtnErrorsSchema] = value;
    return acc;
  }, {} as DtnErrorsSchema);
}

export const getTabFromErrors = (error_key: keyof DtnErrorsSchema) => Object.entries(
  ERROR_MAPPING
).find(([_, value]) => value.includes(error_key))?.[0];