import {DetailedHTMLProps, Dispatch, HTMLAttributes, ReactNode} from "react";
import {ConfigurationSchema} from "@feature/data-entry/types";


export interface PaginationResponseSchema<T extends {}> {
	total: number;
	items: T[];
}

export enum EltType {
	sched = "sched",
	plan = "plan"
}

export enum ProjectType {
	sched = "sched",
	plan = "plan",
	both = "both",
}

export enum EnergyVsWasteType {
	ENERGY = 'ENERGY',
	BALANCED = 'BALANCED',
	WASTE = 'WASTE'
}

export const MapEnergyVsWasteTypeToInt = {
	// Slider Component needs a number as 'value' prop.
	[EnergyVsWasteType.ENERGY]: 0,
	[EnergyVsWasteType.BALANCED]: 1,
	[EnergyVsWasteType.WASTE]: 2,
}

export enum ClientVsDeadlineType {
	CLIENT = 'CLIENT',
	BALANCED = 'BALANCED',
	DEADLINE = 'DEADLINE'
}

export const MapClientVsDeadlineTypeToInt = {
	// Slider Component needs a number as 'value' prop.
	[ClientVsDeadlineType.CLIENT]: 0,
	[ClientVsDeadlineType.BALANCED]: 1,
	[ClientVsDeadlineType.DEADLINE]: 2,
}

export interface AnagramParamType<T extends EnergyVsWasteType | ClientVsDeadlineType> {
	value: number,
	label: string,
	db_value: T
}

export interface DataValidationType {
	field: string,
	valid: boolean
}


export enum FormAction {
	addTitle = 'addTitle',
	addDescription = 'addDescription',
	addClientVsDeadline = 'addClientVsDeadline',
	addEnergyVsWaste = 'addEnergyVsWaste',
	addDEValidation = 'addDEValidation',
	massiveParameterUpdate = 'massiveParameterUpdate'
}

export interface Action {
	type: FormAction,
	payload?: string | boolean | ClientVsDeadlineType | EnergyVsWasteType | DataValidationType[] | ConfigurationSchema
}

export interface NewRunType {
	title: string,
	description?: string,
	client_vs_deadline_param: ClientVsDeadlineType,
	energy_vs_waste_param: EnergyVsWasteType,
	DEValidation: DataValidationType[]
}

export interface DispatchStateProps {
	dispatch: Dispatch<Action>
	state: NewRunType
}

export interface TitleAndDescriptionProps extends DispatchStateProps {
	eltType: EltType
}

export interface NotificationType {
	title: string,
	type: "success" | "error" | "warning" | "info"
}

export interface BlurredPlaceholderProps {
	loading: boolean,
	classNames?: string
	children: ReactNode
}

export type DateFiltersType = [Date, Date]


export interface SelectOptionsSchema {
	/** Label */
	label: string;
	/** Value */
	value: string;
}

export enum Status {
	CREATED = 'CREATED',
	STAGING_STARTED = 'STAGING - STARTED',
	STAGING_ENDED_SUCCESS = 'STAGING - ENDED - SUCCESS',
	STAGING_ENDED_ERROR = 'STAGING - ENDED - ERROR',
	DATA_ENTRY_STARTED = 'DATA ENTRY - STARTED',
	DATA_ENTRY_ENDED_SUCCESS = 'DATA ENTRY - ENDED - SUCCESS',
	DATA_ENTRY_ENDED_ERROR = 'DATA ENTRY - ENDED - ERROR',
	TRN_PLAN_STARTED = 'TRN PLAN - STARTED',
	TRN_PLAN_ENDED_SUCCESS = 'TRN PLAN - ENDED - SUCCESS',
	TRN_PLAN_ENDED_ERROR = 'TRN PLAN - ENDED - ERROR',
	TRN_SCHED_STARTED = 'TRN SCHED - STARTED',
	TRN_SCHED_ENDED_SUCCESS = 'TRN SCHED - ENDED - SUCCESS',
	TRN_SCHED_ENDED_ERROR = 'TRN SCHED - ENDED - ERROR',
	PLANNER_STARTED = 'PLANNER - STARTER',
	PLANNER_ENDED_SUCCESS = 'PLANNER - ENDED - SUCCESS',
	PLANNER_ENDED_ERROR = 'PLANNER - ENDED - ERROR',
	SCHEDULER_STARTED = 'SCHEDULER - STARTER',
	SCHEDULER_ENDED_SUCCESS = 'SCHEDULER - ENDED - SUCCESS',
	SCHEDULER_ENDED_ERROR = 'SCHEDULER - ENDED - ERROR',
	PRS_PLAN_STARTED = 'PRS PLAN - STARTED',
	PRS_PLAN_ENDED_SUCCESS = 'PRS PLAN - ENDED - SUCCESS',
	PRS_PLAN_ENDED_ERROR = 'PRS PLAN - ENDED - ERROR',
	PRS_SCHED_STARTED = 'PRS SCHED - STARTED',
	PRS_SCHED_ENDED_SUCCESS = 'PRS SCHED - ENDED - SUCCESS',
	PRS_SCHED_ENDED_ERROR = 'PRS SCHED - ENDED - ERROR',
}

// extending Status with normal strings
export type StatusType = Status | string


/** RunFullSchema */
export interface RunFullSchema {
	/** An enumeration. */
	client_vs_deadline_param: ClientVsDeadlineType;
	/** An enumeration. */
	energy_vs_waste_param: EnergyVsWasteType;
	/** Title */
	title: string;
	/** Description */
	description?: string;
	/** User Id */
	user_id?: number;
	/** Id Plan */
	id_plan?: number;
	/**
	 * Date
	 * @format date
	 */
	date: string;
	/**
	 * Timestamp
	 * @format date-time
	 */
	timestamp: string;
	/** Discr Param */
	discr_param?: string;
	/** Status */
	status: Status | string;
	/** Saturazione */
	saturazione?: number;
	/** Otif */
	otif?: number;
	/** Id */
	id: number;
	/** Flg Default Run */
	flg_default_run: boolean;
}

export interface AnagramParamsPropsType {
	eltType: EltType,
	allowLaunch?: boolean,
	runParams?: NewRunType
}

export interface StatusBadgePropsType extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	status: Status,
}

export interface ModalProps {
  title?: string,
  description?: string,
  opened: boolean,
  onDismiss?: () => void,
  onConfirm?: () => void,
  onExit: () => void,
  confirmText?: string,
  dismissText?: string,
  type?: "info" | "error" | "warning" | "success"
}

export type timeType = { hour: number, minute: number }

export interface TimePickerPropsType {
  label: string
  value: timeType
  onHourChange: (newValue: number) => void
  onMinuteChange: (newValue: number) => void
}