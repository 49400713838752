import {useContext, useReducer, useState} from "react";
import Slider from "@ui-components/Slider";
import Button from "@ui-components/Button";
import {AnagramParamsPropsType, ClientVsDeadlineType, MapEnergyVsWasteTypeToInt, MapClientVsDeadlineTypeToInt, EnergyVsWasteType, FormAction} from "@feature/commons/types"
import {handleParamsSave, runParamsReducer} from "@feature/data-entry/lib";
import {useIntl} from "react-intl";
import {NotificationsContext} from "@ui-components/Notifications";
import {useConfiguration} from "@feature/commons/hooks";
import {BlurredPlaceholder} from "@feature/commons";
import {BookmarkIcon, PlayIcon} from "@heroicons/react/24/outline";
import {getMarks, launchNewRun} from "@feature/commons/lib";
import {useHistory} from "react-router";

export function AnagramParams({eltType, allowLaunch = false, runParams}: AnagramParamsPropsType) {
	
	const intl = useIntl();
	
	const notification = useContext(NotificationsContext);
	
	const history = useHistory();
	
	const [launchLoading, setLaunchLoading] = useState<boolean>(false);
	
	const [currentConfiguration, dispatch] = useReducer(
		runParamsReducer,
		{
			client_vs_deadline_param: ClientVsDeadlineType.BALANCED,
			energy_vs_waste_param: EnergyVsWasteType.BALANCED,
		}
	);
	
	const loading = useConfiguration(dispatch, eltType);
	
	return (
		<BlurredPlaceholder loading={loading}>
			<div className="flex flex-col max-h-screen min-h-[70vh] items-center justify-evenly w-full">
				<h1 className="text-am-600 text-2xl font-bold uppercase">{intl.formatMessage({id: 'anagram_params'})}</h1>
				<div className="w-3/4 grid grid-cols-1 col-span-5 gap-y-12">
					<Slider
						step={null}
						marks={getMarks(ClientVsDeadlineType, intl)}
						valueLabelFormat={(val: number) => Object.values(ClientVsDeadlineType)[val]}
						defaultValue={1}
						max={2}
						onChange={(_: Event, val: number) => dispatch({
							type: FormAction.addClientVsDeadline,
							payload: Object.values(ClientVsDeadlineType)[val]
						})}
						value={MapClientVsDeadlineTypeToInt[currentConfiguration.client_vs_deadline_param]}
					/>
					<Slider
						step={null}
						marks={getMarks(EnergyVsWasteType, intl)}
						valueLabelFormat={(val: number) => Object.values(EnergyVsWasteType)[val]}
						defaultValue={1}
						max={2}
						onChange={(_: Event, val: number) => dispatch({
							type: FormAction.addEnergyVsWaste,
							payload: Object.values(EnergyVsWasteType)[val]
						})
						}
						value={MapEnergyVsWasteTypeToInt[currentConfiguration.energy_vs_waste_param]}
					/>
				</div>
				<div className="flex items-center justify-evenly w-full">
					<Button styleType="white" onClick={() => handleParamsSave(
						currentConfiguration,
						eltType,
						notification.push,
						intl
					)}>
						<BookmarkIcon className="w-5 h-5 text-am-200 fill-orange-300 mr-2"/>
						{intl.formatMessage({id: "save_configuration"})}
					</Button>
					{
						allowLaunch && runParams &&
            <Button
              styleType="default"
              onClick={() => launchNewRun(
								eltType,
	              {...runParams, ...currentConfiguration},
	              history.push,
	              setLaunchLoading,
	              notification.push,
	              intl
              )}
              submitting={launchLoading}>
              <PlayIcon className="w-5 h-5 text-am-200 fill-green-200 mr-2"/>
							{intl.formatMessage({id: "launch_new_scheduling"})}
            </Button>
					}
				</div>
			</div>
		</BlurredPlaceholder>
	)
}