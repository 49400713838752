import React from "react";
import {IntlShape} from "react-intl";
import {MTColumnsType} from "@utils/pagination";
import {CustomEditDatePicker, CustomFilterDatePicker} from "@components/Tables/Params/CustomDatePicker";
import {RunFullSchema} from "../types";
import LoadingTooltip from "@ui-components/LoadingTooltip";
import {EltType, Status} from "@feature/commons/types";

function _getStatusValuesFromKeys(keys: string[]): string[] {
	const values: string[] = [];
	
	keys.forEach(key => {
		const value = Status[key as keyof typeof Status];
		if (value !== undefined) {
			values.push(value);
		}
	});
	
	return values;
}


type T = RunFullSchema;


export const getColumns = (intl: IntlShape, eltType: EltType): MTColumnsType<T> => [
	{
		title: "id",
		field: "id",
	},
	{
		title: intl.formatMessage({id: "title"}),
		field: "title"
	},
	{
		title: intl.formatMessage({id: "description"}),
		field: "description"
	},
	{
		title: "Stato",
		field: "status",
		lookup: Status,
		// FIXME: Can we increase filter width to better read options?
		render: rowData => <LoadingTooltip status={rowData.status as Status}/>,
		cellStyle: {padding: '0 !important'},
		customFilterAndSearch: (term, rowData) => {
			if (term && term.length > 0) {
				// Translate keys selected into respective values in Status enum.
				const statusValues = _getStatusValuesFromKeys(term);
				return statusValues.includes(rowData.status);
			} else {
				return true;
			}
		}
	},
	...[
		eltType === EltType.plan
			? {
				title: "Default Run",
				field: "flg_default_run",
				type: "boolean"
			}
			: ({} as any)
	],
	{
		title: "Data",
		field: "date",
		type: "date",
		render: rowData => <>{new Intl.DateTimeFormat("it-IT").format(new Date(rowData.date))}</>,
		editComponent: props => <CustomEditDatePicker {...props}/>,
		filterComponent: (props: any) => <CustomFilterDatePicker {...props}/>
	}
]

export const getRunMapping = (eltType: EltType, intl: IntlShape) => {
	const msg = (msgId: string, translation_value?: Record<string, any>) => intl.formatMessage({id: msgId}, translation_value);

	return {
		[msg("title")]: "title",
		[msg("description")]: "description",
		[msg("status")]: "status",
		...(eltType === EltType.plan ? {[msg("default_run")]: "flg_default_run"} : {}),
		[msg("date")]: "date",
	}
}