import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Label from "./Label";

/**
 * @typedef {Object} Option
 *
 * @property {string} label - Label of option.
 * @property {string} value - Value of option.
 */

/**
 * @typedef {Object} NestedOptions
 *
 * @property {string} label - Label of nested options.
 * @property {Array<Option> } options - Nested options.
 */

const style =
  "border shadow-sm focus:ring-am-500 focus:border-am-500 block w-full sm:text-sm border-gray-300 rounded-3xl disabled:opacity-50";

const componentStyle = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "1.3rem",
    borderColor: state.isFocused ? "black" : "transparent",
    boxShadow: "none !important",
    "&:hover": {
      borderColor: "black",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    position: "absolute",
  }),
  input: (provided, state) => ({
    input: {
      border: 0,
      outline: 0,
      boxShadow: "none !important",
      borderColor: state.isFocused ? "black" : "transparent",
    },
  }),
};

const getOptionsValues = (options) => options.map((elem) =>
  (elem["options"] !== undefined) ? {
    label: elem["name"] !== undefined ? elem.name : elem.label,
    options: getOptionsValues(elem["options"])
  } : {
    value: elem.value,
    label: elem["name"] !== undefined ? elem.name : elem.label
  });


const SearchSelect = ({
  loadOptions = null,
  label = "",
  options,
  onChange,
  value,
  isMulti = false,
  CustomOption = null,
  classNames = '',
  labelStyle = '',
  ...props
}) => {
  let component = null;

  const components = CustomOption
    ? { ...(props.components || {}), Option: CustomOption }
    : props.components;

  if (props.components) {
    delete props.components;
  }

  if (!loadOptions) {
    component = (
      <Select
        components={components}
        styles={componentStyle}
        className={style}
        isSearchable={true}
        options={getOptionsValues(options)}
        onChange={onChange}
        value={value}
        placeholder=""
        isMulti={isMulti}
        {...props}
      />
    );
  } else {
    component = (
      <AsyncSelect
        components={components}
        cacheOptions
        styles={componentStyle}
        value={value}
        loadOptions={loadOptions}
        onChange={onChange}
        className={style}
        isMulti={isMulti}
        placeholder=""
        {...props}
      />
    );
  }
  return (
    <div className={`flex flex-col ${classNames}`}>
      {label ? <Label classNames={labelStyle}>{label}</Label> : null}
      {component}
    </div>
  );
};

export default SearchSelect;
