import {useParams} from "react-router";
import {useIntl} from "react-intl";
import {useState} from "react";
import {PageHeading} from "@ui-components/Container";
import Button from "@ui-components/Button";
import {BrokenTable} from "@feature/commons/broken-tables";
import {SchedulationDetails} from "@feature/sched";
import {RunDetailsVisualizationsSched as RDVS, SchedulingDetailsProps} from "@feature/sched/types";
import {EltType} from "@feature/commons/types";


export function SchedSummary({
                               homeRunId,
                               client_vs_deadline_param,
                               energy_vs_waste_param,
                               enableEdit
                             }: SchedulingDetailsProps) {

  const {id_run} = useParams<{ id_run?: string }>();

  const intl = useIntl();

  const title = intl.formatMessage({id: "schedule_details"})

  const [currentVisualization, setCurrentVisualization] = useState<RDVS>(RDVS.schedule_details);

  const renderVisualization = (currentViz: RDVS) => {
    switch (currentViz) {
      case RDVS.schedule_details:
        return <SchedulationDetails
          homeRunId={homeRunId}
          client_vs_deadline_param={client_vs_deadline_param}
          energy_vs_waste_param={energy_vs_waste_param}
          enableEdit={enableEdit}
        />
      case RDVS.orders_not_scheduled:
        return <BrokenTable
          elt_type={EltType.sched}
          homeRunId={homeRunId}
        />
    }
  }

  return (
    <div className="flex flex-col gap-y-12 mb-10">
      {
        id_run ?	// If id_run is undefined, this component is in home-page: don't render the PageHeading with "return" button.
          <PageHeading title={`${title}: ${id_run}`} contentActions={undefined} historyBackPath="/runs_scheduling"/> :
          null
      }
      <div className="flex 2xl:w-1/2 h-20 justify-evenly items-center">
        {
          Object.keys(RDVS).map(b => (
            <Button
              key={b}
              styleType={b === currentVisualization ? "default" : "white"}
              onClick={() => setCurrentVisualization(b as RDVS)}
              children={intl.formatMessage({id: b})}/>
          ))
        }
      </div>
      {
        renderVisualization(currentVisualization)
      }
    </div>
  )
}