import React from "react";
import {useIntl} from "react-intl";
import MaterialTable from "@material-table/core";
import {GanttBlocksType, GanttTableProps} from "@feature/commons/gantt/types";
import {getCommonProps} from "@feature/commons/lib";
import {getColumns} from "./params";
import TableStyle from "@ui-components/TableStyle";
import {downloadExcel} from "@utils/downloadExcel";
import {EltType} from "@feature/commons/types";
import {EyeIcon} from "@heroicons/react/24/outline";

type T = GanttBlocksType;

export function GanttTable({idRun, eltType, categoriesData, setShowGantt}: GanttTableProps) {
	const intl = useIntl()
	
	const commonProps = getCommonProps<T>(intl);
	
	const columns = getColumns(intl);
	return (
		<TableStyle>
			<MaterialTable
				columns={columns}
				{...commonProps}
				data={categoriesData}
				title=""
				actions={[
					...
						eltType === EltType.plan && !!setShowGantt ? 
						[{
								icon: () => <EyeIcon
									className="rounded-full text-am-600 hover:bg-am-600 hover:text-white p-1.5 h-8 w-8"
								/>,
								onClick: (rowData: T) => setShowGantt(rowData),
								isFreeAction: false,
						}] :
						[],
					{
						...commonProps.downloadAction,
						onClick: () => downloadExcel<T>({
							exportData: categoriesData,
							columns: columns,
							name: `sched_data_${idRun}`
						})
					},
				]}
			/>
		</TableStyle>
	)
}
