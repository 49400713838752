import React from "react";
import {useHistory} from "react-router";
import {ArrowLongLeftIcon} from "@heroicons/react/24/outline";

const Container = ({children}) => {
  return (
    <div className="container mx-auto px-6 max-w-7xl md:px-8">{children}</div>
  );
};

const PageHeading = ({title, contentActions, historyBackPath = "", pastState = {}}) => {

  const {push} = useHistory();

  return (
    <div className="pt-8 pb-10 md:flex md:items-center md:justify-between">
      <div className="flex min-w-0">
        {
          historyBackPath &&
          <ArrowLongLeftIcon
            className="inline-block h-9 w-9 cursor-pointer mr-4"
            onClick={() => push(historyBackPath, pastState)}
          />
        }
        <h1 className="text-3xl pb-2 font-extrabold leading-7 text-am-800 sm:text-3xl sm:truncate">
          {/* added pb-2 to avoid bottom-cut of "g","q" letters */}
          {title}
        </h1>
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">{contentActions}</div>
    </div>
  );
};

const PageSection = ({title, description, children}) => (
  <div className="mb-12">
    {title && (
      <>
        <div className="mb-1">
          <div className="space-y-1">
            <h3 className="text-lg leading-6 font-medium text-am-800">
              {title}
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">{description}</p>
          </div>
        </div>
        <div className="mb-5">
          <div className="w-full border-t border-gray-300"/>
        </div>
      </>
    )}
    {children}
  </div>
);

export default Container;
export {Container, PageHeading, PageSection};
