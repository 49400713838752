import {Color} from "./theme.types";

export const chartGenericPalette: Array<Color> = [
  "rgb(0, 60, 80)",
  "rgb(8, 127, 140)",
  "rgb(90, 170, 149)",
  "rgb(134, 168, 115)",
  "rgb(152, 151, 102)",
  "rgb(187, 159, 6)",
  "rgb(222, 164, 54)",
  "rgb(234, 151, 59)",
  "rgb(234, 120, 59)",
  "rgb(206, 96, 76)",
  "rgb(238, 130, 168)",
  "rgb(232, 131, 219)",
  "rgb(166, 101, 183)",
  "rgb(109, 97, 150)",
  "rgb(80, 103, 121)",
  "rgb(67, 80, 107)",
  "rgb(89, 97, 115)"
]
export const palette: Array<Color> = [
  "#00475C",
  "#9CA79D",
  "rgb(0, 71, 92)",
  "rgb(0, 108, 141)",
  "rgb(0, 54, 70)",
  "#2C2A26",
  "#00475C",
  "#9CA79D",
  "rgb(0, 71, 92)",
  "rgb(0, 108, 141)",
  "rgb(0, 54, 70)",
  "#2C2A26",
  "#00475C",
  "#9CA79D",
  "rgb(0, 71, 92)",
  "rgb(0, 108, 141)",
  "rgb(0, 54, 70)",
  "#2C2A26",
]


export const okColor = "#7aea87";
export const warningColor = "#f7e87a";
export const errorColor = "#f77a7a";