import React, {useLayoutEffect, useState} from "react";
import {renderToString} from "react-dom/server";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {useIntl} from "react-intl";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {GanttBlocksType, GanttChartProps} from "@feature/commons/gantt/types";
import {addLicense} from "@components/Charts/commons";
import {
  computeChartHeight,
  getLabelsAndColors,
  getUniqueYValues,
  saveChanges
} from "@feature/commons/gantt/gantt-chart/lib";
import Button from "@ui-components/Button";
import {EditModal} from "@feature/commons/gantt/edit-modal";
import {EltType} from "@feature/commons/types";
import moment from "moment";


const ganttRowSize = 100;

export function GanttChart({
                             id,
                             categoriesData,
                             setCategoriesData,
                             editable = false,
                             machineOptions,
                             setRefetchBlocks,
                             filterParams,
                             eltType,
                             enableEdit
                           }: GanttChartProps) {

  const [editBlock, setEditBlock] = useState<GanttBlocksType>();
  const [editGantt, setEditGantt] = useState<boolean>(false);

  const intl = useIntl();

  useLayoutEffect(() => {

    addLicense();

    const chartHeight = computeChartHeight(categoriesData, ganttRowSize);

    const categoriesLabels = getLabelsAndColors(categoriesData);

    const root = am5.Root.new(id);

    // Theme
    root.setThemes([am5themes_Animated.new(root)]);

    root.dateFormatter.setAll({
      dateFormat: "yyyy-MM-dd",
      dateFields: ["valueX", "openValueX"],
    });

    // Init chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        paddingLeft: 5,
        paddingRight: 30,
      })
    );

    /**
     * Chart scrollable container
     */
    const scrollableContainer = chart.chartContainer.children.unshift(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
          dx: 10,
        }),
      })
    );
    // Container settings
    chart.yAxesAndPlotContainer.set("height", chartHeight);
    chart.yAxesAndPlotContainer.set("paddingBottom", 10);
    chart.zoomOutButton.set("forceHidden", true);

    // Wheel handler
    scrollableContainer.events.on("wheel", (ev) => {
      if (ev.originalEvent.ctrlKey) {
        ev.originalEvent.preventDefault();
        chart.set("wheelX", "panX");
        chart.set("wheelY", "zoomX");
      } else {
        chart.set("wheelX", "none");
        chart.set("wheelY", "none");
      }
    });
    // Push chart in container
    scrollableContainer.children.push(chart.yAxesAndPlotContainer);

    const oneDay = 1000 * 60 * 60 * 24;

    /**
     * X axis
     */
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: {timeUnit: "minute", count: 1},
        renderer: am5xy.AxisRendererX.new(root, {}),
        min: Math.min(...categoriesData.map(b => new Date(b.tms_start).getTime())),
        // Add 1 day to max date to avoid the last block to be cut off
        max: Math.max(...categoriesData.map(b => new Date(b.tms_end).getTime())) + oneDay,
      })
    );

    /**
     * Y axis
     */
    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "cod_machine",
        paddingRight: 15,
        renderer: am5xy.AxisRendererY.new(root, {inversed: true}),
      })
    );
    yAxis.set("minHeight", chartHeight);

    yAxis.data.setAll(getUniqueYValues(categoriesData));

    // Add time scrollbar
    const scrollbarX = am5.Scrollbar.new(
      root, {
        orientation: "horizontal",
        marginTop: 20,
        marginBottom: 20,
      });


    chart.set("scrollbarX", scrollbarX);

    // Add scrollbar on top of the chart
    chart.chartContainer.children.unshift(scrollbarX);

    /**
     * Legend
     */

    const legend = chart.children.unshift(am5.Legend.new(root, {
      nameField: "des_block",
      fillField: "color",
      strokeField: "color",
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: am5.GridLayout.new(root, {
        maxColumns: 5,
        fixedWidthGrid: true
      }),
      marginBottom: 25
    }));

    /**
     * Series (Column)
     */
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        openValueXField: "tms_start",
        valueXField: "tms_end",
        categoryYField: "cod_machine",
        sequencedInterpolation: true,
      })
    );
    // Series settings (with tooltip)
    series.columns.template.setAll({
      templateField: "columnSettings",
      tooltipX: am5.percent(50),
      tooltipY: am5.percent(50),
    });
    series.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["tms_start", "tms_end"],
      dateFormat: "yyyy-MM-dd HH:mm",
    });
    // Open  Modal
    series.columns.template.events.on("click", (ev) => {
      const blockData = ev.target.dataItem?.dataContext as GanttBlocksType
      setEditBlock(blockData)
    })

    // Hide/Show bullets on width changes
    series.columns.template.onPrivate("width", (width, target) =>
      am5.array.each(target!.dataItem!.bullets!, (bullet) =>
        !width || width < 25
          ? bullet.get("sprite").hide()
          : bullet.get("sprite").show()
      )
    );

    // Bullets
    series.bullets.push((root) => am5.Bullet.new(root, {
      locationX: 0.5,
      locationY: 0.5,
      dynamic: true,
      sprite: am5.Label.new(root, {
        text: "{des_block}",
        fill: am5.color(0xffffff),
        fontWeight: "600",
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        populateText: true,
        breakWords: true,
        rotation: -90,
        oversizedBehavior: "truncate",
        ellipsis: "...",
        visible: false,
      }),
    }));

    // tooltipHTML
    series.columns.template.adapters.add("tooltipHTML", (_, target) => {
        const {
          cod_type_interval,
          tms_start,
          tms_end
        } = target.dataItem?.dataContext as GanttBlocksType;
        return renderToString(
          <div style={{maxWidth: 450}} className="flex flex-col items-center gap-y-2 text-white">
            <span><strong>Attività: </strong>{cod_type_interval}</span>
            <span><strong>Inizio: </strong>{moment(new Date(tms_start)).format("DD/MM/YYYY HH:mm:ss")}</span>
            <span><strong>Fine: </strong>{moment(new Date(tms_end)).format("DD/MM/YYYY HH:mm:ss")}</span>
          </div>
        )
      }
    );

    series.data.setAll(categoriesData);
    legend.data.setAll(categoriesLabels);
    chart.appear(1000, 100).then(() => {
    });


    return () => root.dispose()

  }, [categoriesData, id, editGantt])

  return (
    <>
      {
        editable &&
        <div className="flex justify-between mx-10 my-5 gap-x-3">
          {
            editGantt &&
            <>
              <Button onClick={() => saveChanges(categoriesData, filterParams, setRefetchBlocks, EltType.sched)}>
                {intl.formatMessage({id: "confirm_changes"})}
              </Button>
              <Button onClick={() => setRefetchBlocks(p => !p)}>
                {intl.formatMessage({id: "discard_changes"})}
              </Button>
            </>
          }
          {
            enableEdit && (
              <Button classNames="ml-auto" onClick={() => setEditGantt(p => !p)}>
                {intl.formatMessage({id: editGantt ? 'exit_edit' : 'edit'})}
              </Button>
            )
          }
        </div>
      }
      {/* Given hardcoded minHeight (350) and maxHeight (700) to avoid the chart to collapse or explode (height is based on categories cardinality). */}
      <div style={{
        width: '100%',
        maxWidth: '100%',
        height: computeChartHeight(categoriesData, ganttRowSize),
        minHeight: 350,
      }} id={id}/>
      {
        editGantt && editBlock &&
        <EditModal
          eltType={eltType}
          block={editBlock}
          setBlock={setEditBlock}
          machineOptions={machineOptions}
          categoriesData={categoriesData}
          setCategoriesData={setCategoriesData}
          editGantt={editGantt}
        />
      }
    </>
  )
}
