import {ClientVsDeadlineType, EnergyVsWasteType} from "@feature/commons/types";

export interface ConfigurationSchema {
  /** An enumeration. */
  client_vs_deadline_param: ClientVsDeadlineType;
  /** An enumeration. */
  energy_vs_waste_param: EnergyVsWasteType;
}


export enum AgType {
  stg = "stg",
  dtn = "dtn"

}


export interface GenericDataEntrySchema {
  id: number;
  cod_ag_type: AgType;
  flg_modified: boolean;
  flg_disabled: boolean;
}


export interface ManutenzioniSchema extends GenericDataEntrySchema {
  id_utente: number;
  cod_risorsa: string
  /* Tms Inizio Manutenzione */
  /* @format date-time */
  tms_inizio_manutenzione: string;
  /* Tms Fine Manutenzione */
  /* @format date-time */
  tms_fine_manutenzione: string;
  category: string;
  user_mail: string;
}


export interface OrdiniSimulatiSchema extends GenericDataEntrySchema {
  id_utente: number;
  cod_ordine: string;
  cod_parte: string;
  val_pose_previsto: number;
  val_pezzi_ordinati: number;
  /* Dat Inserimento */
  /* @format date */
  dat_inserimento: string;
  /* Dat Consegna */
  /* @format date */
  dat_consegna: string;
  des_cliente: string;
  cod_materia_prima: string;
  qta_materia_prima_necessaria: number;
  dat_arrivo_materia_prima: string;
  flg_attivo: boolean;
  cod_risorsa_prevista?: string;
}

export interface DtnErrorsSchema {
  /** Unprioritized Customers */
  unprioritized_customers: number;
  /** Articles Missing Approvation Status */
  articles_missing_approvation_status: number;
  /** Unapproved Articles */
  unapproved_articles: number;
  /** Missing Order Infos */
  missing_order_infos: number;
  /** Couples Missing Infos */
  couples_missing_infos: number;
  /** Couples No Revisions */
  couples_no_revisions: number;
}

export interface DataEntryTableProps {
  tab: DtnTableSchema;
  errorAlert?: DtnErrorsSchema
  errorTrigger: () => void;
}

export interface DataEntryUrlState {
  tab?: string,
  title?: string,
  description?: string
}

export interface DtnTableSchema {
  table_name: string;
  user_can_add: boolean;
  user_can_edit: boolean;
  user_can_delete: boolean;
}
