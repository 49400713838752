import {IntlShape} from "react-intl";
import {MTColumnsType} from "@utils/pagination";
import {GenericDataEntrySchema, ManutenzioniSchema, OrdiniSimulatiSchema,} from "@feature/data-entry/types";
import {checkIfNotEmpty} from "@feature/data-entry/lib";
import {CustomEditDatePicker, CustomFilterDatePicker} from "@components/Tables/Params/CustomDatePicker";
import React from "react";

const getCommonColumns = <T extends GenericDataEntrySchema>(intl: IntlShape): MTColumnsType<T> => [
  {
    title: "id",
    field: "id",
    hidden: true
  },
  {
    title: intl.formatMessage({id: "compiled"}),
    field: "flg_modified",
    type: "boolean",
    editable: "never",
  }
]


const getManutenzioniColumns = (intl: IntlShape): MTColumnsType<ManutenzioniSchema> => [
  {
    title: intl.formatMessage({id: "user"}),
    field: "user_mail",
    editable: "never",
    sorting: false,
    pagination: {
      spech: {
        column_name: ["utente.email"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "cod_resource"}),
    field: "cod_risorsa",
    pagination: {
      spech: {
        column_name: ["cod_risorsa"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "maintenance_start_date"}),
    field: "tms_inizio_manutenzione",
    type: "datetime",
    filterComponent: (props) => <CustomFilterDatePicker {...props} showTimePicker/>,
    editComponent: (props) => <CustomEditDatePicker {...props} showTimePicker/>,
  },
  {
    title: intl.formatMessage({id: "maintenance_end_date"}),
    field: "tms_fine_manutenzione",
    type: "datetime",
    filterComponent: (props) => <CustomFilterDatePicker {...props} showTimePicker/>,
    editComponent: (props) => <CustomEditDatePicker {...props} showTimePicker/>,
  },
  {
    title: intl.formatMessage({id: "category"}),
    field: "category",
    pagination: {
      spech: {
        column_name: ["category"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  }
]


const getOrdiniSimulatiColumns = (intl: IntlShape): MTColumnsType<OrdiniSimulatiSchema> => [
  {
    title: intl.formatMessage({id: "cod_order"}),
    field: "cod_ordine",
    editable: "onAdd",
    validate: rowData => checkIfNotEmpty(rowData.cod_ordine, intl),
    pagination: {
      spech: {
        column_name: ["cod_ordine"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: `${intl.formatMessage({id: "cod_article"})} / MTEC`,
    field: "cod_parte",
    validate: rowData => checkIfNotEmpty(rowData.cod_parte, intl),
    pagination: {
      spech: {
        column_name: ["cod_parte"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "num_expected_poses"}),
    field: "val_pose_previsto",
    type: "numeric",
    editable: "onAdd",
    validate: rowData => checkIfNotEmpty(rowData.val_pose_previsto, intl)
  },
  {
    title: intl.formatMessage({id: "ordered_pieces"}),
    field: "val_pezzi_ordinati",
    type: "numeric",
    editable: "onAdd",
    validate: rowData => checkIfNotEmpty(rowData.val_pezzi_ordinati, intl)
  },
  {
    title: intl.formatMessage({id: "order_insertion_date"}),
    field: "dat_inserimento",
    type: "date",
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
    editable: "never"
  },
  {
    title: intl.formatMessage({id: "delivery_date"}),
    field: "dat_consegna",
    type: "date",
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
    editable: "onAdd",
    validate: rowData => checkIfNotEmpty(rowData.dat_consegna, intl)
  },
  {
    title: intl.formatMessage({id: "customer"}),
    field: "des_cliente",
    editable: "onAdd",
    validate: rowData => checkIfNotEmpty(rowData.des_cliente, intl),
    pagination: {
      spech: {
        column_name: ["des_cliente"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "raw_material_code"}),
    field: "cod_materia_prima",
    validate: rowData => checkIfNotEmpty(rowData.cod_materia_prima, intl),
    pagination: {
      spech: {
        column_name: ["cod_materia_prima"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "necessary_raw_material"}),
    field: "qta_materia_prima_necessaria",
    type: "numeric",
    validate: rowData => checkIfNotEmpty(rowData.qta_materia_prima_necessaria, intl)
  },
  {
    title: intl.formatMessage({id: "raw_material_arrival_date"}),
    field: "dat_arrivo_materia_prima",
    type: "date",
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
  },
  {
    title: intl.formatMessage({id: "status"}),
    field: "flg_attivo",
    type: "boolean",
    validate: rowData => checkIfNotEmpty(rowData.flg_attivo, intl)
  },
]


export const getColumns = <T extends GenericDataEntrySchema>(
  table_name: string,
  intl: IntlShape
): MTColumnsType<T> => {
  let columns: MTColumnsType<T> = []

  switch (table_name) {
    case "manutenzioni":
      columns = getManutenzioniColumns(intl) as MTColumnsType<T>;
      break;
    case "ordini_simulati":
      columns = getOrdiniSimulatiColumns(intl) as MTColumnsType<T>;
      break;
    default:
      throw new Error(`Table ${table_name} not found`);

  }

  return columns.concat(getCommonColumns<T>(intl));
}